import { useState } from "react";
import CheckBox from "./CheckBox";
import RenderCheck from "./RenderCheck";
import CN25 from "../assets/CNs/Convertible Debt Note - 25 Lac-1.pdf";
import CN50 from "../assets/CNs/Convertible Debt Note - 50 Lac-1.pdf";
import CN100 from "../assets/CNs/Convertible Debt Note - 100 Lac-1.pdf";
import SB from "../assets/Copy of Start Up builder_ SBA_07.04.2021.pdf";
import NPA from "../assets/Note Purchase Agreement Template-2.pdf";
import { Link } from "react-router-dom";
import { useContext } from "react";
import AppContext from "../contexts/AppContext";

function Schema({
	title,
	points = [],
	paymentPercentage,
	agreements = [],
	downloadPDF = false,
	className = "",
	canProceed,
	paymentAmount,
	onSelect,
	_id,
}) {
	const [hasAgreed, setHasAgreed] = useState(false);
	const { user } = useContext(AppContext);
	return (
		<div className='' data-aos='fade-up' data-aos-delay='200'>
			<div className={`schema ${className}`}>
				<div className='price'>
					Rs{" "}
					{paymentAmount.toString().includes("1")
						? 100
						: paymentAmount.toString()[0] + paymentAmount.toString()[1]}
				</div>
				<h2>{title}</h2>
				<ul className='points'>
					{points.map((p, idx) => (
						<li key={idx}>
							<RenderCheck />
							<span>{p}</span>
						</li>
					))}
				</ul>

				<span className='percentage'>{paymentPercentage}+ </span>
				<p className='ooo'>Potential % equity</p>
				<ul className='agreements'>
					{agreements.map((p, idx) => (
						<a
							href={
								idx === 0
									? SB
									: idx === 2
									? paymentPercentage === 3
										? CN25
										: paymentPercentage === 6
										? CN50
										: CN100
									: NPA
							}
							target='_blank'
							rel='noopener noreferrer'
							key={idx}
						>
							<li>
								<svg
									width='13'
									height='16'
									viewBox='0 0 13 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M7.91634 0.916016H2.24967C1.87395 0.916016 1.51362 1.06527 1.24794 1.33095C0.982263 1.59662 0.833008 1.95696 0.833008 2.33268V13.666C0.833008 14.0417 0.982263 14.4021 1.24794 14.6678C1.51362 14.9334 1.87395 15.0827 2.24967 15.0827H10.7497C11.1254 15.0827 11.4857 14.9334 11.7514 14.6678C12.0171 14.4021 12.1663 14.0417 12.1663 13.666V5.16602L7.91634 0.916016ZM4.72742 10.9673C4.50855 11.1727 4.18555 11.2648 3.80942 11.2648C3.73649 11.2656 3.6636 11.2613 3.59126 11.252V12.2621H2.95801V9.4741C3.24379 9.43147 3.53255 9.41204 3.82147 9.41602C4.21601 9.41602 4.49651 9.4911 4.68563 9.64197C4.86555 9.78506 4.98738 10.0195 4.98738 10.2958C4.98667 10.5734 4.89459 10.8079 4.72742 10.9673ZM7.42405 11.9271C7.12655 12.1743 6.67392 12.2918 6.12072 12.2918C5.78922 12.2918 5.55476 12.2706 5.39538 12.2493V9.47481C5.68127 9.43308 5.96994 9.41343 6.25884 9.41602C6.79505 9.41602 7.14355 9.51235 7.41555 9.71777C7.70951 9.93593 7.89367 10.2837 7.89367 10.7831C7.89367 11.3236 7.69605 11.6968 7.42405 11.9271ZM10.0413 9.96143H8.95617V10.6067H9.97051V11.1266H8.95617V12.2628H8.31442V9.43727H10.0413V9.96143ZM7.91634 5.87435H7.20801V2.33268L10.7497 5.87435H7.91634Z'
										fill='#E33861'
									/>
								</svg>

								<span>{p}</span>
							</li>
						</a>
					))}
				</ul>
				<Link
					to={
						user ? `/ideas?schema=${_id}` : `/login?next=/ideas?schema=${_id}`
					}
					className='select_inv'
				>
					Select this Investment Option
				</Link>
				{canProceed && (
					<>
						<div className='check_schm'>
							<CheckBox
								label='I have read, comprehended the scheme, and I want to proceed '
								onChange={setHasAgreed}
							/>
						</div>
						<button
							onClick={onSelect}
							disabled={!hasAgreed}
							className={`schema_btn ${hasAgreed ? "" : "disabled"}`}
						>
							Continue
						</button>
					</>
				)}
			</div>
		</div>
	);
}

export default Schema;
