import { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import "../css/hero.css";
import ClearHead from "./ClearHead";

function Hero(props) {
	const { user } = useContext(AppContext);
	return (
		<>
			<ClearHead />
			<div id='home' className='ubuntu container hero'>
				<h2 data-aos='fade-down' data-aos-delay='100'>
					<span>Innovation-Powered Alternative Investment Opportunities </span>{" "}
					-{">"} Exclusive Non-Tradable Startup Investment Options
				</h2>
				<p data-aos='fade-down' data-aos-delay='200'>
					Are you a pre-seed fund? Do you seek numerous investment
					opportunities? Ready to invest immediately?
					<br />
					Take the leap of faith first.
					<br /> Welcome to INVEST2EXIT!
					<br />
					(✓) Invest, exit, & gain (✓) Achieve multifold returns effortlessly
					(✓) Practice angel investing on csPILLAI® (✓) Explore & select your
					investment option (✓) Choose & fund a tech idea affordably (✓) Track
					investment progress in real-time (✓) Avail a 540-day exit option @ 2x
					payout*
				</p>
				<Link
					data-aos='fade-down'
					data-aos-delay='300'
					to={user ? "#investmentoptions" : "/register"}
					className='btb_h'
				>
					Get Started
				</Link>
			</div>
		</>
	);
}

export default Hero;
