import { useState, useContext } from "react";
import { useNavigate } from "react-router";
import urls from "../api/urls";
import noimage from "../assets/products/noimage.png";
import configs from "../configs/configs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import AppContext from "../contexts/AppContext";
import useClient from "../hooks/useClient";
import Modal from "./Modal";
import Button from "./Button";
import { Formik } from "formik";
import * as Yup from "yup";
import Loader from "./Loader";

const getPercentage = (fneeded, fraised) =>
	((fraised / fneeded) * 100).toFixed(0);

function PIdea({
	name,
	schemaId,
	image,
	solutions = [],
	_id,
	fundsNeeded,
	fundsRaised,
}) {
	const { user, schemas } = useContext(AppContext);
	const [displayModal, setDisplayModal] = useState({
		first: false,
		second: false,
		third: false,
		fourth: false,
	});
	const defaultAmount = schemas.find(
		(schema) => schema._id === schemaId
	).paymentAmount;

	// const isFunded = getPercentage(fundsNeeded, fundsRaised) >= 100;
	const isFunded = false;

	const navigate = useNavigate();
	const { post } = useClient();

	const initContract = async (amount, currency) => {
		const { data, error } = await post(
			urls.service.ideas + "/" + _id + urls.service.fund,
			{
				schema: schemaId,
				amount,
				currency,
			}
		);
		return [data, error];
	};

	const handleClick = () => {
		setDisplayModal((prev) => ({
			...prev,
			first: true,
		}));
	};

	const proceed = async ({ paymentMethod, currency, amount }) => {
		let amountToPay = paymentMethod === "part" ? amount : defaultAmount;
		const data = await initContract(amountToPay, currency);

		if (data[0]) {
			setDisplayModal({
				first: false,
				second: true,
			});
		} else {
			if (data[1].message === "Contract already initiated.") {
				setDisplayModal({
					first: false,
					fourth: true,
				});
			} else {
				setDisplayModal({
					first: false,
					third: true,
				});
			}
		}
	};

	const closeModal = () => {
		setDisplayModal({
			first: false,
			second: false,
			third: false,
		});
	};

	if (!schemaId) return null;
	return (
		<>
			{displayModal.first && (
				<Modal setDisplayModal={closeModal}>
					<div className='sign'>
						<h2>Signing of the Startup Builder Agreement (SBA)</h2>

						<p>
							<span>Note: </span>You are about to e-sign an SBA for the {name}{" "}
							idea!
						</p>

						<Formik
							onSubmit={proceed}
							initialValues={{
								paymentMethod: "",
								currency: "inr",
								amount: "",
							}}
							validationSchema={Yup.object().shape({
								paymentMethod: Yup.string().required("Select a payment method"),
								currency: Yup.string().when("paymentMethod", {
									is: "part",
									then: Yup.string().required("Select the currency"),
								}),
								amount: Yup.number().when("paymentMethod", {
									is: "part",
									then: Yup.number()
										.min(850000, "Amount should not be less than 8.5lacs")
										.required("Input the amount you want to pay"),
								}),
							})}
						>
							{({
								handleSubmit,
								handleChange,
								values,
								touched,
								errors,
								isSubmitting,
							}) => (
								<form
									onSubmit={handleSubmit}
									noValidate
									className='payment-form'
								>
									<select
										name='paymentMethod'
										value={values.paymentMethod}
										onChange={handleChange}
									>
										<option value='' disabled defaultValue>
											Choose payment method
										</option>
										<option value='complete'>Total</option>
										<option value='part'>Other</option>
									</select>

									{values.paymentMethod === "part" && (
										<>
											{/* <select
                        name="currency"
                        value={values.currency}
                        onChange={handleChange}
                      >
                        <option value="" disabled defaultValue>
                          Choose currency
                        </option>
                        <option value="inr">INR</option>
                        <option value="usd">USD</option>
                      </select> */}

											<input
												name='amount'
												type='number'
												placeholder='Amount in rupees'
												value={values.amount}
												onChange={handleChange}
											/>
										</>
									)}

									<button className='btn manrope button' type='submit'>
										{isSubmitting ? (
											<Loader loading={isSubmitting} />
										) : (
											"Proceed Now"
										)}
									</button>
									{touched.paymentMethod && errors.paymentMethod ? (
										<div className='error'>{errors.paymentMethod}</div>
									) : null}
									{touched.amount && errors.amount ? (
										<div className='error'>{errors.amount}</div>
									) : null}
								</form>
							)}
						</Formik>
					</div>
				</Modal>
			)}
			{displayModal.second && (
				<Modal setDisplayModal={closeModal}>
					<div className='sign'>
						<svg
							width='100'
							height='100'
							viewBox='0 0 143 143'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M71.4993 131.083C87.9527 131.083 102.849 124.414 113.631 113.631C124.414 102.849 131.083 87.9527 131.083 71.4993C131.083 55.046 124.414 40.1502 113.631 29.3676C102.849 18.5851 87.9527 11.916 71.4993 11.916C55.046 11.916 40.1502 18.5851 29.3676 29.3676C18.5851 40.1502 11.916 55.046 11.916 71.4993C11.916 87.9527 18.5851 102.849 29.3676 113.631C40.1502 124.414 55.046 131.083 71.4993 131.083Z'
								stroke='#DA2F72'
								strokeWidth='2'
								strokeLinejoin='round'
							/>
							<path
								d='M47.666 71.5L65.541 89.375L101.291 53.625'
								stroke='#DA2F72'
								strokeWidth='2'
								strokeLinecap='round'
								strokeLinejoin='round'
							/>
						</svg>

						<h2>SBA link Sent</h2>

						<p>
							Please check your email to sign. You will receive a link from
							notifications@pichainlabs.com within 48hrs for your signature.
						</p>

						<Button title='OK thanks' className='button' onClick={closeModal} />
					</div>
				</Modal>
			)}
			{displayModal.third && (
				<Modal setDisplayModal={closeModal}>
					<div className='sign'>
						<p>Something went wrong</p>

						<Button title='Ok' className='button' onClick={closeModal} />
					</div>
				</Modal>
			)}
			{displayModal.fourth && (
				<Modal setDisplayModal={closeModal}>
					<div className='sign'>
						<p>You have already initiated a contract with this idea!</p>

						<Button title='Ok' className='button' onClick={closeModal} />
					</div>
				</Modal>
			)}
			<div className='sidea' data-aos='fade-up'>
				<div className='funded'>
					{getPercentage(fundsNeeded, fundsRaised)}% Funded
				</div>
				<div className='img'>
					<img
						src={
							image
								? `${configs.BACKEND_URL}${urls.fileStreams.baseUrl}/${image}`
								: noimage
						}
						alt={name}
					/>
				</div>
				<h3>Codename: {name}</h3>
				<p>What problems does this Idea solve?</p>
				<ul>
					{solutions.map((s, idx) => (
						<li key={idx}>{s}</li>
					))}
				</ul>
				<div className='btts'>
					<button
						style={
							isFunded
								? {
										paddingLeft: 15,
										paddingRight: 15,
										paddingTop: 10,
										paddingBottom: 10,
										color: "white",
										borderWidth: 0,
								  }
								: {}
						}
						disabled={isFunded}
						className={isFunded ? "disabled" : ""}
						onClick={user ? handleClick : navigate("/login")}
					>
						{isFunded ? "Funded" : "Fund"}
					</button>
					{!isFunded && (
						<CopyToClipboard
							onCopy={() => alert("Link copied to clipboard")}
							text={`${configs.FRONTEND_URL}/ideas/fund?id=62e0e8a99b967570f701c259`}
						>
							<button>
								Share{" "}
								<svg
									width='17'
									height='12'
									viewBox='0 0 17 12'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M9.29173 3.3977V0.332031L13.0181 3.66616L16.0059 6.33941L12.9651 8.60678L9.29173 11.3459V8.34611C2.84282 7.9112 0.583402 11.2503 0.583402 11.2503C0.583402 9.16991 0.774983 7.01091 2.60294 5.37607C4.72778 3.4742 7.80498 3.33961 9.29173 3.3977Z'
										fill='#212121'
									/>
								</svg>
							</button>
						</CopyToClipboard>
					)}
				</div>
			</div>
		</>
	);
}

export default PIdea;
