import RenderLogoWithText from "./RenderLogoWithText";
import "../css/header.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import AppContext from "../contexts/AppContext";
import { useRef } from "react";
import useClient from "../hooks/useClient";

const links = [
	{
		title: "Home",
		to: "https://cspillai.com/",
	},
	{
		title: "Steps",
		to: "#steps",
	},
	{
		title: "Benefits",
		to: "#benefits",
	},
	{
		title: "Investment Options",
		to: "#investmentoptions",
	},
	{
		title: "Share",
		to: "#share",
	},
	{
		title: "Support",
		to: "https://cspillai.com/support",
	},
	{
		title: "Follow",
		to: "https://linktr.ee/cspillai",
	},
];

function Header(props) {
	const [active, setActive] = useState(links[0].title.toLowerCase());
	const [isOpened, setIsOpened] = useState(false);
	const [path, setPath] = useState("");
	const p = useLocation();
	const headerRef = useRef();
	const { logout } = useClient();
	const { setHeaderHeight, user } = useContext(AppContext);

	const updateHeight = () => {
		setHeaderHeight(headerRef.current.clientHeight);
	};

	useEffect(() => {
		const name = p.hash.replace("#", "");
		setIsOpened(false);
		if (name) {
			const el = document.getElementById(name);
			if (!el) return;
			el.scrollIntoView({ behavior: "smooth" });
			setActive(name);
		}
	}, [p.hash]);

	useEffect(() => {
		setPath(p.pathname.replace("/", ""));
	}, [p.pathname]);

	useEffect(() => {
		updateHeight();
		window.addEventListener("resize", updateHeight);
		return () => window.removeEventListener("resize", updateHeight);
	}, []);
	return (
		<header
			ref={headerRef}
			style={{ backgroundColor: p.pathname === "/" ? "#F7F7F7" : "#FFFFFF" }}
			className={`container manrope flex header-padding  align-center justify-between header-${path}`}
		>
			<a href='https://cspillai.com/'>
				<RenderLogoWithText className='llap' />
			</a>

			<ul className='desktop flex' data-aos='fade-down' data-aos-delay='400'>
				{links.map((link, idx) => (
					<li
						className={link.title.toLowerCase() === active ? "active" : ""}
						key={idx}
					>
						<a href={link.to}>{link.title}</a>
					</li>
				))}
				{user && <li onClick={logout}>Logout</li>}
			</ul>
			<nav className={`mobile nav ${isOpened ? "opened" : ""}`}>
				<ul className='flex'>
					{links.map((link, idx) => (
						<li
							className={link.title.toLowerCase() === active ? "active" : ""}
							key={idx}
						>
							<a href={link.to}>{link.title}</a>
						</li>
					))}
					{user && <li onClick={logout}>Logout</li>}
				</ul>
			</nav>
			<div
				onClick={() => setIsOpened(!isOpened)}
				className='menu mobile'
				data-aos='fade-down'
				data-aos-delay='400'
			>
				<div></div>
				<div></div>
				<div></div>
			</div>
			{/* <Link className="manrope desktop linkbgf btn_ge" to="/get-started">
        Get Started
      </Link> */}
		</header>
	);
}

export default Header;
