import ClearHead from "../components/ClearHead";
import PIdea from "../components/PIdea";
import "../css/ideasp.css";
import { useState } from "react";
import Input from "../components/Input";
import { useContext } from "react";
import AppContext from "../contexts/AppContext";
import "../css/ideas.css";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { useEffect } from "react";

function Ideas(props) {
	const { ideas, schemas } = useContext(AppContext);
	const [render, setRender] = useState(ideas);
	const [displayModal, setDisplayModal] = useState(true);

	useEffect(() => {
		setRender(ideas);
	}, [ideas]);

	const { search } = useLocation();
	const navigate = useNavigate();
	const schemaId = search.replace("?schema=", "");

	const updateRender = (e) => {
		setRender(
			ideas.filter((p) => p.name.toLowerCase().includes(e.toLowerCase()))
		);
	};

	const instructionsModalDisplayed = localStorage.getItem("instructions-modal");

	const handleClick = () => {
		localStorage.setItem("instructions-modal", true);
		setDisplayModal(false);
	};

	if (!schemaId || !schemas.find((s) => s._id === schemaId))
		return navigate("/");

	if (!ideas || !ideas.length) {
		return (
			<div className='loader_wrapper'>
				<Loader className='' loading={true} />
			</div>
		);
	}

	return (
		<>
			<ClearHead />
			{!instructionsModalDisplayed && displayModal && (
				<Modal setDisplayModal={handleClick} onClick={handleClick}>
					<div className='steps'>
						<h2 className='heading'>Steps for funding an idea</h2>

						<ol className='list'>
							<li> Sign SBA (e-Sign)</li>
							<li>Another link will be sent to you for payment</li>
							<li>Sign SBA (e-Stamp)</li>
							<li>
								Onboard to your dashboard and monitor your investment as it
								grows
							</li>
						</ol>
						<Button title='Continue' className='button' onClick={handleClick} />
					</div>
				</Modal>
			)}
			<div className='container manrope containerh ideas_list'>
				<h2 className='htitle'>Tech Startup Ideas</h2>
				<div className='search'>
					<Input placeholder='Search by keyword' onChange={updateRender} />
					<div className='svv'>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M23.1855 20.3857L18.7068 15.907C18.5047 15.7048 18.2307 15.5925 17.9432 15.5925H17.2109C18.4508 14.0068 19.1875 12.0123 19.1875 9.84253C19.1875 4.68101 15.0053 0.498779 9.84375 0.498779C4.68223 0.498779 0.5 4.68101 0.5 9.84253C0.5 15.0041 4.68223 19.1863 9.84375 19.1863C12.0135 19.1863 14.008 18.4496 15.5937 17.2097V17.9419C15.5937 18.2294 15.7061 18.5035 15.9082 18.7056L20.3869 23.1843C20.8092 23.6066 21.492 23.6066 21.9098 23.1843L23.1811 21.913C23.6033 21.4908 23.6033 20.808 23.1855 20.3857ZM9.84375 15.5925C6.66777 15.5925 4.09375 13.023 4.09375 9.84253C4.09375 6.66655 6.66328 4.09253 9.84375 4.09253C13.0197 4.09253 15.5937 6.66206 15.5937 9.84253C15.5937 13.0185 13.0242 15.5925 9.84375 15.5925Z'
								fill='white'
							/>
						</svg>
					</div>
				</div>
				<div className='plist itmlist'>
					{render
						.sort((a, b) =>
							a.fundsRaised < b.fundsRaised
								? 1
								: b.fundsRaised < a.fundsRaised
								? -1
								: 0
						)
						.map((p, idx) => (
							<PIdea schemaId={schemaId} {...p} key={idx} />
						))}
				</div>
			</div>
		</>
	);
}

export default Ideas;
