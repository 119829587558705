import RenderCheck from "./RenderCheck";
import Title from "./Title";
import "../css/benefits.css";

const benefits = [
	"Companies Act compliant shares",
	"2x potential returns or 59% CAGR",
	"Profitable with payback/conversion",
	"Amazing 540-day startup finish",
	"Progress e-dashboards access",
	"5-star rated financial e-reporting",
];

function Benefits(props) {
	return (
		<div id='benefits' className='container manrope benefits'>
			<div className='yt' data-aos='fade-up' data-aos-delay='200'>
				<div className='iframe'>
					<iframe
						src='https://www.youtube.com/embed/RzL_WAaV8N4'
						title='How it works'
						frameBorder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
						allowFullScreen
					></iframe>
				</div>
			</div>
			<div className='ben' data-aos='fade-up' data-aos-delay='400'>
				<Title title='Benefits' />
				<ul className='bn_ul'>
					{benefits.map((b, idx) => (
						<li key={idx}>
							<RenderCheck />
							<span>{b}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
}

export default Benefits;
