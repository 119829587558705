import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Share from "./components/Share";
import C404 from "./pages/404";
import AppContext from "./contexts/AppContext";
import Home from "./pages/Home";
import { useEffect, useState } from "react";
import Ideas from "./pages/Ideas";
import Login from "./pages/Login";
import Register from "./pages/Register";
import useClient from "./hooks/useClient";
import Loader from "./components/Loader";
import UpdateProfile from "./pages/UpdateProfile";
import RecoverAccount from "./pages/RecoverAccount";
import Popup from "./components/Popup";
import "./utils/aos";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";

function App(props) {
	const [isReady, setIsReady] = useState(false);
	const [user, setUser] = useState(null);
	const [headerHeight, setHeaderHeight] = useState(0);
	const { restoreUser, isLoading, getIdeas, getSchemas } = useClient();
	const [schemas, setSchemas] = useState([]);
	const [ideas, setIdeas] = useState([]);

	const modalDisplayed = localStorage.getItem("welcome-modal");

	const getUserData = async () => {
		const user = await restoreUser();
		setUser(user);
		setIsReady(true);
	};

	const getIdeasAndSchema = async () => {
		console.log("latest - 08/01/2023");
		const schemas = getSchemas();
		setSchemas(schemas);
		const ideas = await getIdeas();
		setIdeas(ideas);
	};

	useEffect(() => {
		getIdeasAndSchema();
		getUserData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (isLoading || !isReady) return <Loader className='app_loader' loading />;

	return (
		<AppContext.Provider
			value={{ headerHeight, setHeaderHeight, user, setUser, schemas, ideas }}
		>
			{!modalDisplayed && <Popup />}
			<div className='app_cnt bg noscroll'>
				<BrowserRouter>
					<Header />
					<Routes>
						<Route path='/' element={<Home />} />
						<Route path='/ideas' element={<Ideas />} />
						<Route path='/login' element={<Login />} />
						<Route path='/register' element={<Register />} />
						<Route path='/payment-success' element={<PaymentSuccess />} />
						<Route path='/payment-error' element={<PaymentFailure />} />
						<Route path='/profile/update' element={<UpdateProfile />} />
						<Route path='/forgot-password' element={<RecoverAccount />} />
						<Route path='*' element={<C404 />} />
					</Routes>
					<Share />
					<Footer />
				</BrowserRouter>
			</div>
		</AppContext.Provider>
	);
}

export default App;
